<script setup lang="ts">
import { WsBadge, WsButton } from '@mfl/common-components';

import strings from './survey-response.strings';
import { onBeforeMount } from 'vue';
import { deferLoading, loadingDone } from '@mfl/framework';

onBeforeMount(async () => {
  try {
    deferLoading();
    await new Promise((r) => setTimeout(r, 3000));
  } finally {
    loadingDone();
  }
});
</script>

<template>
  <div class="survey-response-container">
    <div class="header">
      <span class="text-2xl font-bold">{{ strings.header }}</span>
      <WsBadge aid="SURVEY_RESPONSE_BADGE" class="badge" label="NEW"></WsBadge>
    </div>

    <WsButton
      aid="SURVEY_RESPONSE_BUTTON"
      class="my-button"
      color="primary"
      :label="strings.buttonLabel"
    />
  </div>
</template>

<style scoped>
.survey-response-container {
  display: flex;
  margin: 12px 24px;
  flex-direction: column;

  .header {
    display: flex;

    .badge {
      margin: 4px 10px;
    }
  }

  .my-button {
    margin-top: 200px;
    width: 300px;
    height: 200px;
    align-self: center;
  }
}
</style>
